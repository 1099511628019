<template>
  <v-app-bar
    class="primary-gradient"
    fixed
    dense
  >
    <v-btn
      dark
      icon
      @click="$store.commit('toggleDrawer')"
    >
      <v-icon dense>
        {{ $store.state.isDrawerOpened ? 'fas fa-bars' : 'fas fa-times' }}
      </v-icon>
    </v-btn>
    <v-toolbar-title class="font-weight-bold mr-2 white--text">
      {{ setModuleName(items) }}
    </v-toolbar-title>

    <v-spacer />

    <AppBarModules />

    <v-spacer />

    <v-btn
      v-if="showSettings"
      @click="$router.push({ name: 'settings' })"
      icon
      dark
    >
      <v-icon>fas fa-cog</v-icon>
    </v-btn>

    <v-btn
      dark
      @click="logout"
      icon
    >
      <v-icon>fas fa-sign-out-alt</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import AppBarModules from './AppBarModules.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AppBar',
  components: {
    AppBarModules,
  },
  data: () => ({
    menu: false,
    moduleName: null,
    settings: { valid_time_budget: null },
    is_valid: false,
    user: {},
    settingsDialog: false,
    items: [
      { title: 'Estoque', icon: 'fas fa-boxes', route: '/stock' },
      { title: 'Pessoas', icon: 'fas fa-users', route: '/people' },
      { title: 'Comercial', icon: 'fas fa-handshake', route: '/comercial' },
      { title: 'Site', icon: 'fas fa-globe', route: '/site' },
    ],
  }),
  computed: {
    ...mapGetters(['showSettings']),
  },
  methods: {
    getSettings() {
      this.$api
        .get('settings', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.$store.commit('setFixedValue', res.data.data.fix_value)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    logout() {
      this.$session.destroy()
      this.$cookies.remove('token')
      this.$cookies.remove('isAuth')
      this.$cookies.remove('name')
      this.$cookies.remove('profile')
      this.$cookies.remove('type')
      this.$cookies.remove('id')
      this.isLogged = false
      sessionStorage.clear()

      this.$router.push({ name: 'admin' })
    },
    setModuleName(items) {
      items.map((item) => {
        if (item.route === this.$route.matched[0].path) {
          this.moduleName = item.title
        }
      })
      return this.moduleName
    },
    getUser() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editUser() {
      this.$router.push({
        name: 'users-edit',
        params: {
          id: this.user.id,
        },
      })
    },
  },
  created() {
    this.getSettings()
  },
}
</script>

<style lang="scss">
.u-object-fit {
  object-fit: cover;
}
</style>

<template>
  <div>
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="canAccessStock"
          text
          v-on="on"
          v-bind="attrs"
          active-class="white primary--text"
          class="white--text mx-4"
        >
          <v-icon
            left
            small
            style="color: inherit"
          >
            fas fa-boxes
          </v-icon>
          <span class="caption">Estoque</span>
        </v-btn>
      </template>
      <StockSections :isShaped="false" />
    </v-menu>

    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="showPeople"
          v-on="on"
          v-bind="attrs"
          text
          active-class="white primary--text"
          class="white--text mx-4"
        >
          <v-icon
            left
            small
            style="color: inherit"
          >
            fas fa-users
          </v-icon>
          <span class="caption">Pessoas</span>
        </v-btn>
      </template>
      <PeopleSections :isShaped="false" />
    </v-menu>

    <!-- Comercial não será exibido para usuários do type editor -->
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="canAccessComercial"
          text
          v-on="on"
          v-bind="attrs"
          active-class="white primary--text"
          class="white--text mx-4"
        >
          <v-icon
            left
            small
            style="color: inherit"
          >
            fas fa-handshake
          </v-icon>
          <span class="caption">Comercial</span>
        </v-btn>
      </template>
      <ComercialSections :isShaped="false" />
    </v-menu>

    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="showSite"
          v-on="on"
          v-bind="attrs"
          text
          active-class="white primary--text"
          class="white--text mx-4"
        >
          <v-icon
            left
            small
            style="color: inherit"
          >
            fas fa-globe
          </v-icon>
          <span class="caption">Site</span>
        </v-btn>
      </template>
      <SiteSections :isShaped="false" />
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
import StockSections from './drawer-sections/StockSections.vue'
import PeopleSections from './drawer-sections/PeopleSections.vue'
import ComercialSections from './drawer-sections/ComercialSections.vue'
import SiteSections from './drawer-sections/SiteSections.vue'

export default {
  mixins: [checkProfileRoutePermission],
  components: {
    StockSections,
    PeopleSections,
    ComercialSections,
    SiteSections,
  },
  computed: {
    ...mapGetters({
      showPeople: 'people/showModule',
      showStock: 'stock/showModule',
      showComercial: 'comercial/showModule',
      showSite: 'site/showModule',
    }),
    // Para acessar estoque, não pode ser seller
    canAccessStock() {
      return this.showStock && this.$cookies.get('type') !== 'seller'
    },
    // Para acessar o módulo Comercial, o usuário não pode ser do tipo editor
    canAccessComercial() {
      return this.showComercial && this.$cookies.get('type') !== 'editor'
    },
  },
}
</script>
